<!--
 * @Description: 详情
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-19 14:35:10
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-20 20:30:05
-->

<template>
  <div class="">
    <div class="w ww xx_detail">
      <div class="bread">{{this.$t('position')}} > <a @click="goBack">{{pageType}}</a> > <a>{{title}}</a></div>
      <h2>{{title}}</h2>
      <div class="xx_detail_1"><span>{{viewNum}}{{this.$t('browse')}}</span><span>{{time}}</span></div>
      <div>
        <div class="pc_fl xx_detail_lf">
          <div class="releate-file">
            <!-- <a  >
              <img :src="docImg" />
            </a> -->
            <h3>相关附件：</h3>
           <p class="fileLabel" v-for="item in fileList" :key="item.owid"><a :href="pictrueUrl+item.filePath" target="_blank">{{item.fileLabel}}</a></p>
          </div>
          <p v-html="docTxt"></p>
          <div v-if="type=='002'">
            <p :class="['dt-like',{'active':likeNum==1}]"
               @click="addLike">
              <img src="@/assets/img/12.png" />{{actLikeNum}}
            </p>
          </div>
        </div>
        <!-- <div class="pc_fr xx_detail_rt">
          <h3>相关资料
            <img src="@/assets/img/30.png"
                 width="100%"
                 class="go-next"
                 v-if="totalPage>1"
                 @click="goNextPage" />
          </h3>
          <ul v-if="rightList.length>0">
            <li v-for="(v,i) in rightList"
                @click="getDetail(v.owid,type=='study'?'goProDetail':'')"
                :key="i">
              <a>
                <div class="fl xx_detail_rt_1"><img :src="v.tpjj" /></div>
                <div :class="{'active':docId==v.owid,'fr xx_detail_rt_2':true}">
                  <h4>{{v.wzbt}}</h4>
                  <p>{{v.jjnr||v.memo}}</p>
                  <span v-if="!v.hideView">{{v.ydcs||0}}次 浏览</span>
                </div>
                <div class="clear"></div>
              </a>
            </li>
          </ul>
          <ul v-if="rightList.length==0">
             <NullTip></NullTip>
          </ul>
        </div> -->
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Com, Study } from '@/api/index'
import { docInfo } from '@/views/technology/model'
import { dealUrlHttp, sliceStr, showLayer } from '@/utils/com';
import { IMGURL } from '@/utils/constant'
import NullTip from "@/components/component/NullTip.vue";
export default {
  name: 'detail',

  data () {
    return {
      docId: this.$route.query?.docId || "",
      type: this.$route.query?.type || "",
      docImg: '',
      docTxt: '',
      title: '',
      time: '',
      fileList:'',
      viewNum: 0,
      actLikeNum: 0,

      rightList: [],//右侧列表
      currentPage: 1,
      totalPage: 1,
      ydcs: 1,//浏览量
      likeNum: "",//点赞量 1已点不可以再次点击
      downNum: "",//下载量
      pictrueUrl:''
    }
  },

  computed: {
    pageType () {
      if (this.type == '002')
      {
        return this.$t('technicalService')
      }
      if (this.type == 'study')
      {
        return this.$t('LearningCenter')
      }
    }
  },

  created () {
    this.pictrueUrl =  IMGURL
    this.getDetail();
    this.goNextPage();

    if (this.type == '002')
    {
      this.addViewNum();
    }
   
  },

  methods: {

    //下一页
    async goNextPage () {
      let res;
      this.type == "002" && (res = await docInfo({ zxlb: this.type, pageNo: this.currentPage, pageSize: 6 }));
      this.type == "study" && (res = await this.getStudyOther());
      if (res)
      {
        if (this.type == "002")
        {
          let { records = [], totalPage, currentPage } = res;
          records.forEach(v => { v.tpjj = dealUrlHttp(v.tpjj) });
          this.rightList = records || [];
          this.totalPage = totalPage || 1;
          this.totalPage == currentPage ? this.currentPage = 1 : this.currentPage = currentPage + 1
        } else
        {
          let arr = [];
          let obj = {};
          Array.isArray(res) && res.forEach((v, i) => {
            if (i < 10)
            {
              obj = {
                tpjj: IMGURL + v.productPic,
                owid: v.owid,
                jjnr: v.productDesc || v.memo || "",
                wzbt: v.productName || "",
                ydcs: '',
                hideView: true
              }
              arr.push(obj);
            }
          })
          this.rightList = arr
        }
      }
    },

    //获取文章详情
    async getDetail (owid = this.docId, type) {
      if (type == "goProDetail")
      {
        this.$router.push({ path: "productdetails", query: { proId: owid } });
        return
      }
      let res = {};
      owid && (this.docId = owid);
      this.type == '002' && (res = await Com.newsDetail({ owid }));
      this.type == 'study' && (res = await Study.studyDetails({ owid }));
      this.fileList =res.fileList
      if (this.type == '002')
      { 
        this.docTxt = res.wznr;
        this.title = res.wzbt;
        this.time = sliceStr(res.fbsj, 0, 16);
        this.docImg = dealUrlHttp(res.tpjj)
        this.viewNum = res.ydcs || 0;
        this.actLikeNum = res.likeNum || 0

        this.likeNum = ""//重置点赞状态 可以重新点击
        this.ydcs = 1;
      }
      if (this.type == 'study')
      {
        this.docTxt = res.studyContent;
        this.title = res.studyTitle;
        this.time = sliceStr(res.studyPublishTime, 0, 16);
        this.docImg = dealUrlHttp(res.studyShortImg)
        this.viewNum = res.studyClick || 0;
      }
    },

    //点赞数+1
    async addViewNum () {
      let res = await Com.addNewsNum({ owid: this.docId, ydcs: this.ydcs, likeNum: this.likeNum, downNum: this.downNum });
      this.ydcs = "";//浏览量重置 防止浏览量重复增加
      return res;
    },

    //点击赞
    async addLike () {
      if (!this.likeNum)
      {
        this.likeNum = 1
        await this.addViewNum();
        this.actLikeNum = this.actLikeNum + 1;
      } else
      {
        showLayer('toast', '您已点过赞');
      }
    },

    goBack () {
      this.$router.go(-1);//返回上一层
    },

    //获取学习其它资料
    async getStudyOther () {
      let res = await Study.studyOther({ studyRefOwid: this.docId });
      return res
    }
  },
  components: {
    NullTip
  },
}
</script>

<style lang="less" scoped>
.go-next {
  width: 0.17rem;
  float: right;
}
.xx_detail_rt h3 {
  border-bottom: 1px solid #ececec;
  overflow: hidden;
  line-height: 0.2rem;
  height: 0.2rem;
  padding-left: 0.07rem;
  background: #f5f5f5;
  color: #32b16c;
  cursor: pointer;
}

.xx_detail_rt_2.active {
  h4,
  p,
  span {
    color: #32b16c;
  }
}

.xx_detail_rt_1 img {
  height: 80px;
  border-radius: 4px;
}

.dt-like {
  display: inline-block;
  border: 1px solid #eaeaea;
  text-align: center;
  text-indent: 0;
  padding: 0px 20px;
  margin-top: 15px;
  float: right;
  cursor: pointer;
  border-radius: 4px;
}
.dt-like img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}
.fileLabel {
  width: 100%;
  height: 0.2rem;
  line-height: 0.2rem;
  cursor: pointer;
  
}
.fileLabel a {
  color: #32b16c;
  font-size: 0.08rem;
}
.releate-file {
  margin-top: 0.2rem;
}
</style>
